import axios from "axios";

const base_url = process.env.REACT_APP_BASE_URL;

export const getBaseUrl = () => {
    return base_url;
}

export const getDistrictsHavingShops = async () => {
    try {
        const response = await axios.get(base_url + 'api/districts/shops')
        return response.data;
    }
    catch (error){
        throw error;
    }
}

export const getDistrictsHavingMTOs = async () => {
    try {
        const response = await axios.get(base_url + 'api/districts/mtos')
        return response.data;
    }
    catch (error){
        throw error;
    }
}

export const getVillagesHavingShops = async (districtId) => {
    try {
        const response = await axios.get(base_url + 'api/villages/shops/' + districtId)
        return response.data;
    }
    catch (error){
        throw error;
    }
}

export const getVillagesHavingMTOs = async (districtId) => {
    try {
        const response = await axios.get(base_url + 'api/villages/mtos/' + districtId)
        return response.data;
    }
    catch (error){
        throw error;
    }
}

export const getDistrictsHavingEssnMTOs = async () => {
    try {
        const response = await axios.get(base_url + 'api/districts/essn')
        return response.data;
    }
    catch (error){
        throw error;
    }
}

export const getVillagesHavingEssnMTOs = async (districtId) => {
    try {
        const response = await axios.get(base_url + 'api/villages/essn/' + districtId)
        return response.data;
    }
    catch (error){
        throw error;
    }
}

export const getAllShops = async (page = null) => {
    try {
        let response = null;

        if(page) {
            response = await axios.get(base_url + 'api/shops?page=' + page);
        } else {
            response = await axios.get(base_url + 'api/shops');
        }
        
        return response.data;
    }
    catch (error){
        throw error;
    }
}

export const getShopByDistrict = async (districtId, page = null) => {
    try {
        let response = null;

        if(page) {
            response = await axios.get(base_url + 'api/shops/district/' + districtId + '?page=' + page);
        } else {
            response = await axios.get(base_url + 'api/shops/district/' + districtId);
        }
        
        return response.data;
    }
    catch (error){
        throw error;
    }
}

export const getShopByVillage = async (villageId, page = null) => {
    try {
        let response = null;

        if(page) {
            response = await axios.get(base_url + 'api/shops/village/' + villageId + '?page=' + page);
        } else {
            response = await axios.get(base_url + 'api/shops/village/' + villageId);
        }
        
        return response.data;
    }
    catch (error){
        throw error;
    }
}

export const getAllMtos = async (page = null) => {
    try {
        let response = null;

        if(page) {
            response = await axios.get(base_url + 'api/money-transfer-operators?page=' + page);
        } else {
            response = await axios.get(base_url + 'api/money-transfer-operators');
        }
        
        return response.data;
    }
    catch (error){
        throw error;
    }
}

export const getMtosByVillage = async (villageId, page = null) => {
    try {
        let response = null;

        if(page) {
            response = await axios.get(base_url + 'api/money-transfer-operators/village/' + villageId + '?page=' + page);
        } else {
            response = await axios.get(base_url + 'api/money-transfer-operators/village/' + villageId);
        }
        
        return response.data;
    }
    catch (error){
        throw error;
    }
}

export const getMtosByDistrict = async (districtId, page = null) => {
    try {
        let response = null;

        if(page) {
            response = await axios.get(base_url + 'api/money-transfer-operators/district/' + districtId + '?page=' + page);
        } else {
            response = await axios.get(base_url + 'api/money-transfer-operators/district/' + districtId);
        }
        
        return response.data;
    }
    catch (error){
        throw error;
    }

}

export const getAllEssnMtos = async (page = null) => {
    try {
        let response = null;

        if(page) {
            response = await axios.get(base_url + 'api/essn-money-transfer-operators?page=' + page);
        } else {
            response = await axios.get(base_url + 'api/essn-money-transfer-operators');
        }
        
        return response.data;
    }
    catch (error){
        throw error;
    }
}

export const getEssnMtosByVillage = async (villageId, page = null) => {
    try {
        let response = null;

        if(page) {
            response = await axios.get(base_url + 'api/essn-money-transfer-operators/village/' + villageId + '?page=' + page);
        } else {
            response = await axios.get(base_url + 'api/essn-money-transfer-operators/village/' + villageId);
        }
        
        return response.data;
    }
    catch (error){
        throw error;
    }
}

export const getEssnMtosByDistrict = async (districtId, page = null) => {
    try {
        let response = null;

        if(page) {
            response = await axios.get(base_url + 'api/essn-money-transfer-operators/district/' + districtId + '?page=' + page);
        } else {
            response = await axios.get(base_url + 'api/essn-money-transfer-operators/district/' + districtId);
        }
        
        return response.data;
    }
    catch (error){
        throw error;
    }
}




